import { isPlatformBrowser } from '@angular/common';
import { InjectionToken } from '@angular/core';
import { Request as Request_2 } from 'express';

export const SEO_BOT = new InjectionToken<boolean>('viewer');
export const CRAWLER_AGENTS = [
    'googlebot',
    'yandexbot',
    'yahoo',
    'bingbot',
    'baiduspider',
    'facebookexternalhit',
    'twitterbot',
    'rogerbot',
    'linkedinbot',
    'embedly',
    'quora link preview',
    'showyoubot',
    'outbrain',
    'pinterest/0.',
    'developers.google.com/+/web/snippet',
    'slackbot',
    'vkshare',
    'w3c_validator',
    'redditbot',
    'applebot',
    'whatsapp',
    'flipboard',
    'tumblr',
    'bitlybot',
    'skypeuripreview',
    'nuzzel',
    'discordbot',
    'google page speed',
];

export const CHECK_BOT = (platformId: string, req: Request_2): boolean => {
    if (isPlatformBrowser(platformId)) {
        return false;
    }

    const userAgent = (req.get('user-agent') || '').toLowerCase();
    return CRAWLER_AGENTS.some((crawlerAgent) => userAgent.indexOf(crawlerAgent) !== -1);
};
